<template>
  <div class="mcontainer">
    <div>
      <login-header></login-header>
    </div>

    <div class="mmian">
      <img src="/pic/sy2.png" alt="">
      <div class="center"> 会员中心 </div>

    </div>
    <div class="mcontent">
      <div class="mleft">
        <div :class="{ 'mmenu': true, 'mselect': item.isSelect }" @click="changeMenu(item)" v-for="item in menu">
          {{ item.name }}</div>

      </div>
      <div class="mright">
        <div class="mbao">{{ currentmenu }}


          <div class="notice">
            <img class="tongzhi" src="@/assets/tongzhi.png" alt="">
            <div>
              <swiper class="noticespan" :options="swiperOption">
                <swiper-slide class="swiper-slide" v-for="(item, index) in slide" :key="index">
                  <span> {{ item }}</span>
                </swiper-slide>
                <!-- 分页器 -->
                <div class="swiper-pagination" slot="pagination"></div>

              </swiper>
            </div>



          </div>
        </div>
        <div class="contentview">
          <router-view></router-view>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import loginHeader from "@/components/login/loginheader";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { membersbuyrecord } from '@/api/member'
import { get } from "http";

export default {
  components: {
    loginHeader,
    Swiper,
    SwiperSlide,
  },
  computed: {
    userinfo: {
      get() {
        return this.$store.state.userinfo !== '' ? JSON.parse(this.$store.state.userinfo) : ''
      },
      set() {
        this.$store.dispatch('userinfo')
      }
    }
  },
  data() {
    return {
      swiperOption: {

        direction: 'vertical',
        autoplay: {
          disableOnInteraction: false,
          delay: 1000,

        },
        loop: true


      },
      slide: [],
      currentmenu: "个人信息",
      menu: [
        {
          name: "土拍会员",
          route: "/member/buyvip",
          isSelect: true,
        },
        {
          name: "个人信息",
          route: "/member/info",
          isSelect: false,
        },
        {
          name: "订单管理",
          route: "/member/byrecord",
          isSelect: false,
        },
        {
          name: "换绑手机",
          route: "/member/changetel",
          isSelect: false,
        },
        {
          name: "修改密码",
          route: "/member/setpwd",
          isSelect: false,
        },
      ],
    };
  },
  created() {
    this.menu.map((i) => {
      if (i.route === this.$route.path) {
        this.currentmenu = i.name;
        i.isSelect = true;
      } else {
        i.isSelect = false;
      }
      return i;
    });

  },

  watch: {
    userinfo: {
      handler(nv) {
        if (this.userinfo === '' ) {
          this.$store.dispatch('userinfo')
        }
        
      },
      immediate: true,
      deep: true
    }
    
  },

  mounted() {
    this.getmembersbuyrecord()
    console.log('this.userinfo', this.userinfo)
    // if (this.userinfo === '') {
    //   this.$store.dispatch('userinfo')
    // }
  },
  methods: {
    getmembersbuyrecord() {
      membersbuyrecord().then(res => {
        this.slide = res.data
      })
    },

    

    changeMenu(item) {
      var that = this;
      const change = this.menu.map((i) => {
        if (i.name === item.name) {
          i.isSelect = true;
          that.currentmenu = item.name;
          that.$router.push(item.route);
        } else {
          i.isSelect = false;
        }
        return i;
      });
      this.menu = change;
    },
  },
};
</script>

<style lang="scss">
.mcontainer {
  width: 100%;
  min-height: 100vh;
  background: #f0f3f8;
}

.mmian {
  display: flex;
  width: 80%;
  margin: 0 10%;
  background: #ffffff;
  height: 50px;
  background: #ffffff;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 10px;

  img {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .center {
    width: 64px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 22px;
  }
}

.mcontent {
  display: flex;
  width: 80%;
  margin: 0 10%;
  min-height: calc(100vh - 140px);
  overflow: hidden;

  .mleft {
    min-height: calc(100vh - 140px);
    background: #fff;
    display: flex;
    flex-direction: column;
    width: 260px;

    .mmenu {
      width: 260px;
      height: 52px;
      border-bottom: solid 3px #f0f3f8;
      background: #ffffff;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 52px;
      color: #232323;
      cursor: pointer;
    }

    .mselect {
      background: #28a7e1;
      color: #ffffff;
    }
  }

  .mright {
    display: flex;
    flex-direction: column;
    width: calc(100% - 260px);
    padding-left: 10px;
    // align-items: flex-start;
    justify-content: flex-start;

    .mbao {
      display: flex;
      width: 100%;
      height: 40px;
      background: #ffffff;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #232323;
      line-height: 40px;
      text-align: left;
      padding-left: 20px;
      box-sizing: border-box;
      margin-bottom: 10px;
    }

    .contentview {
      padding: 0;
      margin: 0;
      // background:red;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: flex-start;
    }
  }

}

.notice {
  display: flex;
  align-items: center;

  .tongzhi {
    height: 20px;
    width: 20px;
    margin-left: 10px;
    margin-right: 5px;
  }

  .noticespan {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #E77817;
    line-height: 20px;
    flex: 1;

  }
}
</style>